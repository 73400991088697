import React from "react"
import styled from "styled-components"

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(343px, 10fr) 1fr;

  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr minmax(550px, 22fr) 1fr;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: 3fr minmax(550px, 18fr) 3fr;
  }

  > * {
    grid-column: 2 / 3;
  }
`

export default (props) => {
  return <GridContainer {...props}>{props.children}</GridContainer>
}
