import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Grid } from "."
// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookMessenger,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

const FooterWrapper = styled.footer`
  background: var(--hlc-purple);
  color: var(--hlc-white);
  text-align: center;
  font-family: neuzeit-grotesk, sans-serif;
  padding: 2rem 1rem;

  @media screen and (min-width: 800px) {
    padding: 2rem 0;
  }

  .wrapper {
    text-align: left;
    @media screen and (min-width: 800px) {
      display: grid;
      grid-template-columns: minmax(200px, 3fr) minmax(550px, 9fr);
    }
  }
  hr {
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  .info {
    @media screen and (min-width: 800px) {
      grid-column: 1 / 2;
      position: relative;
    }

    .title {
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.1rem;

      @media screen and (min-width: 800px) {
        text-align: left;
      }
    }

    .title::after {
      @media screen and (min-width: 800px) {
        content: "";
        position: absolute;
        top: 1.25em;
        left: 0;
        width: 2.5rem;
        height: 2px;
        background: var(--hlc-teal);
      }
    }

    p {
      line-height: 1.5rem;
    }
  }

  .navbar {
    @media screen and (min-width: 800px) {
      grid-column: 2 / 3;
      display: flex;
      justify-content: space-evenly;
    }
    .option {
      position: relative;

      .toggle {
        display: none;
      }

      .toggle-label,
      .content {
        backface-visibility: hidden;
        transform: translateZ(0);
        transition: all 0.2s;
      }

      .toggle-label {
        display: block;
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        padding: 1em 0;

        @media screen and (min-width: 800px) {
          padding: 0;
        }
      }

      .toggle-label::before,
      .toggle-label::after {
        content: "";
        position: absolute;
        right: 1.25em;
        top: 1.25em;
        width: 3px;
        height: 0.75em;
        background: var(--hlc-white);
        transition: all 0.2s;

        @media screen and (min-width: 800px) {
          all: unset;
        }
      }

      .toggle-label::after {
        transform: rotate(90deg);
        @media screen and (min-width: 800px) {
          all: unset;
          content: "";
          position: absolute;
          top: 1.25em;
          left: 0;
          width: 2.5rem;
          height: 2px;
          background: var(--hlc-teal);
        }
      }

      .content {
        max-height: 0;
        overflow: hidden;

        @media screen and (min-width: 800px) {
          all: unset;
        }

        ul {
          list-style: none;
          padding: 0;
          li {
            line-height: 1.5rem;
            a {
              color: var(--hlc-white);
              text-decoration: none;
            }
            a:hover {
              color: rgba(var(--hlc-white-text), 0.5);
            }
          }
        }
      }

      .toggle:checked {
        & + .toggle-label + .content {
          max-height: 500px;
        }
        & + .toggle-label::before {
          transform: rotate(90deg) !important;

          @media screen and (min-width: 800px) {
            all: unset;
          }
        }
      }
    }
  }

  .copyright-area {
    grid-row: 2;
    grid-column: 1 / 3;
    display: flex;
    justify-content: center;
    padding-top: 1rem;
  }
`

const Footer = () => {
  return (
    <FooterWrapper className="alfred-footer">
      <Grid>
        <div className="grid-content">
          {" "}
          {/* this comes from outside */}
          <div className="wrapper">
            <div className="info">
              {" "}
              {/* this comes from outside */}
              <div className="title">Hunterlily & Co.</div>
              <p>
                In 2017, Hunterlily & Co. was founded by Alfred Chan & Ardie Wen
                in Toronto. Today, our network of entrepreneurs continues to
                grow globally.
              </p>
            </div>
            <div className="navbar">
              <hr />
              <div className="option">
                <input
                  type="checkbox"
                  id="toggle-solutions"
                  className="toggle"
                />
                <label className="toggle-label" htmlFor="toggle-solutions">
                  Solutions
                </label>
                <div className="content">
                  <ul>
                    <li>
                      <Link to="/solutions#experiencedesign">
                        Experience Design
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions#digitalmarketing">
                        Digital Marketing
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions#webdevelopment">
                        Web & App Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions#ecommerce">eCommerce Solutions</Link>
                    </li>
                    <li>
                      <Link to="/solutions#devops">DevOps</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <hr />
              <div className="option">
                <input type="checkbox" id="toggle-about" className="toggle" />
                <label className="toggle-label" htmlFor="toggle-about">
                  About
                </label>
                <div className="content">
                  <ul>
                    <li>
                      <Link to="/about#ourteam">Our Team</Link>
                    </li>
                    <li>
                      <Link to="/about#ourapproach">Our Approach</Link>
                    </li>
                    <li>
                      <Link to="/about#hunter-and-lily">Hunter & Lily</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <hr />
              <div className="option">
                <input type="checkbox" id="toggle-contact" className="toggle" />
                <label className="toggle-label" htmlFor="toggle-contact">
                  Contact
                </label>
                <div className="content">
                  <ul>
                    <li>
                      <a href="https://maps.google.ca">
                        <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth />{" "}
                        Toronto, ON, Canada
                      </a>
                    </li>
                    <li>
                      <a href="mailto:hello@hunterlily.co">
                        <FontAwesomeIcon icon={faEnvelope} fixedWidth />{" "}
                        hello@hunterlily.co
                      </a>
                    </li>
                    <li>
                      <a href="https://m.me/hunterlilyco">
                        <FontAwesomeIcon
                          icon={faFacebookMessenger}
                          fixedWidth
                        />{" "}
                        @hunterlilyco
                      </a>
                    </li>
                    <li>
                      <a href="https://ca.linkedin.com/company/hunterlily-co">
                        <FontAwesomeIcon icon={faLinkedin} fixedWidth />{" "}
                        @hunterlilyco
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <hr />
            </div>
            <div className="copyright-area">
              <span>© 2019 Hunterlily & Co.</span>
            </div>
          </div>
        </div>
      </Grid>
    </FooterWrapper>
  )
}

export default Footer
