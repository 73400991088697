import React, { Component } from "react"
import { Header, Footer } from "."
import { NormalizeStyles, GlobalStyles } from "../../styles"

import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* line-height: 1.5rem; */
`

const Content = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
`

class Layout extends Component {
  constructor(props) {
    super(props)
    this.headerElement = React.createRef()
  }

  changeState = () => {
    this.headerElement.current.setHeaderState()
  }

  componentDidMount() {
    // Element to observe
    const sectionOne = document.querySelector("#SectionOne")

    // Options
    const options = {
      root: null, // Page as root
      threshold: 0.3, // Triggers the callback when the element completely leaves the view
      rootMargin: "0px",
    }

    const callBack = (entries) => {
      entries.forEach((entry) => {
        if (entry.rootBounds !== null) {
          this.changeState()
        }
      })
    }

    //Create an observer
    this.observer = new IntersectionObserver(callBack, options)

    // Observe the element
    this.observer.observe(sectionOne)
  }

  render() {
    return (
      <>
        <NormalizeStyles />
        <GlobalStyles />
        <Container>
          <Content>
            <Header ref={this.headerElement} />
            {this.props.children}
          </Content>
          <Footer />
        </Container>
      </>
    )
  }
}

export default Layout
