import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Grid } from "."
import hlcLogo from "../../images/logos/Hunterlily_Logo_Wordmark.svg"

const HeaderWrapper = styled.div`
  .header {
    background: transparent;
    position: absolute;
    z-index: 999;
    width: 100%;
    padding: 2rem 0 0;
  }

  .sticky-header {
    background: var(--hlc-purple);
    position: fixed;
    z-index: 100;
    width: 100%;
    transition: background 400ms ease-in-out;
    border-bottom: 1px solid var(--hlc-teal);
    padding: 1rem 0 0;
  }

  .site-title {
    margin: 0;
  }

  .screen-reader-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .nav {
    display: flex;
    justify-content: space-between;

    .logo {
      @media screen and (min-width: 800px) {
        grid-column: 2 / 3;
        display: flex;
        align-items: center;
      }

      img {
        height: 48px;
        margin: 0;
      }
    }

    .nav-toggle {
      display: none;
    }

    .nav-toggle:checked ~ .navbar {
      transform: scale(1, 1);
    }

    .nav-toggle:checked ~ .navbar .link {
      opacity: 1;
      transition: opacity 250ms ease-in-out 250ms;
    }

    .hamburger {
      @media screen and (min-width: 800px) {
        display: none;
      }

      .nav-toggle-label {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .nav-toggle-label span,
    .nav-toggle-label span::before,
    .nav-toggle-label span::after {
      display: block;
      background: var(--hlc-white);
      height: 2px;
      width: 2em;
      border-radius: 2px;
      position: relative;
    }

    .nav-toggle-label span::before,
    .nav-toggle-label span::after {
      content: "";
      position: absolute;
    }

    .nav-toggle-label span::before {
      bottom: 8px;
    }

    .nav-toggle-label span::after {
      top: 8px;
    }

    .navbar {
      position: absolute;
      text-align: left;
      top: 100%;
      left: 0;
      width: 100%;
      background: var(--hlc-purple);
      transform: scale(1, 0);
      transform-origin: top;
      transition: transform 400ms ease-in-out;

      @media screen and (min-width: 800px) {
        all: unset;
        grid-column: 3 / 4;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      ul {
        list-style: none;

        @media screen and (min-width: 800px) {
          display: flex;
          justify-content: flex-end;
        }
      }
      li {
        margin-bottom: 1em;
        margin-left: 1em;

        @media screen and (min-width: 800px) {
          margin-left: 1.5em;
          margin-bottom: 0;
        }

        .link {
          color: var(--hlc-white);
          font-family: neuzeit-grotesk, sans-serif;
          font-weight: 700;
          font-size: 2rem;
          line-height: 3rem;
          letter-spacing: 0.1rem;
          text-decoration: none;
          opacity: 0;
          transition: opacity 150ms ease-in-out;

          @media screen and (min-width: 800px) {
            opacity: 1;
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }

        .link:hover {
          color: rgba(var(--hlc-white-text), 0.5);
        }
      }
    }
  }
`
class Header extends Component {
  state = {
    showDefaultHeader: false, // Default State, Change on Observed Scroll Down
    showOpaqueBG: false, // Default State, Change On Nav Open
  }

  setHeaderState = () => {
    this.setState({ showDefaultHeader: !this.state.showDefaultHeader })
  }

  setHeaderBG = () => {
    this.setState({ showOpaqueBG: !this.state.showOpaqueBG })
  }

  render() {
    return (
      <HeaderWrapper>
        <div
          className={`${
            this.state.showDefaultHeader ? "header" : "sticky-header"
          }`}
        >
          <Grid>
            <div className="grid-content">
              <h1 className="site-title" itemProp="headline">
                <span className="screen-reader-only">Hunterlily & Co.</span>
              </h1>
              <nav className="nav">
                <div className="logo">
                  <Link className="link" to="/">
                    <img src={hlcLogo} alt="Hunterlily & Co." />
                  </Link>
                </div>
                <div className="hamburger">
                  <label htmlFor="nav-toggle" className="nav-toggle-label">
                    <span></span>
                  </label>
                </div>
                <input type="checkbox" id="nav-toggle" className="nav-toggle" />
                <div className="navbar">
                  <ul>
                    <li>
                      <Link className="link" to="/solutions">
                        Solutions
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </Grid>
        </div>
      </HeaderWrapper>
    )
  }
}

export default Header

// export default () => (
//   <StaticQuery
//     query={graphql`
//       query {
//         site {
//           siteMetadata {
//             title
//           }
//         }
//       }
//     `}
//     render={data => <Header site={data.site} />}
//   />
// )
