import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li {
    font-family: neuzeit-grotesk, sans-serif;
  }

  h1 {
    font-size: 3rem; /* 48px */
    line-height: 3.5rem; /* 56px */
    letter-spacing: 0.125rem; /* 2px */
  }

  h2 {
    font-size: 2.25rem; /* 36px */
    line-height: 2.75rem; /* 44px */
  }

  h3 {
    font-size: 1.8rem; /* 29px */
    line-height: 2.2rem;
  }

  p {
    font-family: ff-tisa-web-pro, serif;
    letter-spacing: 0.05rem; /* 0.8px */
    line-height: 1.45rem;
  }

  :root {
    --hlc-purple: rgb(47, 52, 89);
    --hlc-purple-text: 47, 52, 89;
    --hlc-teal: rgb(69, 191, 176);
    --hlc-teal-text: 69, 191, 176;
    --hlc-white: rgb(253, 248, 235);
    --hlc-white-text: 253, 248, 235;
    --hlc2-grey: rgb(218, 227, 234);
    --hlc2-grey-text: 218, 227, 234;
    --hlc2-navy: rgb(28, 33, 53);
    --hlc2-navy-text: 28, 33, 53;
  }
`
