import styled from "styled-components"

export const PurpleSection = styled.section`
  background: var(--hlc-purple);

  p {
    color: var(--hlc-white);
  }
`

export const WhiteSection = styled.section`
  background: var(--hlc-white);

  p {
    color: var(--hlc-purple);
  }
`

export const TealSection = styled.section`
  background: var(--hlc-teal);

  p {
    color: var(--hlc-white);
  }
`
